














































import { Vue, Component, Prop } from 'vue-property-decorator'
import { EstoqueProjection, FormDeItemDeEstoque, TipoDeProduto } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DepositoModule} from '@/store/vuex/deposito/DepositoStore'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { FindDepositoUseCase, FindEstoqueUseCase, SaveEstoqueUseCase } from '@/usecases'
import { fixarCasasDecimais } from '@/shareds/formatadores'

@Component({
	components: {
		EdicaoGenerica,
		BuscaDeProdutoDropdown,
	},
})
export default class EdicaoDeItemDeEstoque extends Vue {
	@Prop({ type: String }) idDeposito!: string
	@Prop({ type: String }) id!: string | undefined
	@Prop({ type: Boolean }) novo!: boolean

	estoque: FormDeItemDeEstoque | null = null
	carregando = true
	salvando = false

	qtdeEstoque = 0
	qtdeEmpenhada = 0
	estoqueMinimo = 0
	infoAdicional = ''

	habilitaEstoqueMinimo = DepositoModule.depositoSelecionado?.loja.configuracaoDaLoja.alteraEstoqueMinimo

	erro: null | Error = null

	findDepositoUseCase = new FindDepositoUseCase()
	findEstoqueUseCase = new FindEstoqueUseCase()
	saveEstoqueUseCase = new SaveEstoqueUseCase()

	created() {
		this.carregar()
	}

	async carregar() {
		try {
			this.carregando = true

			this.estoque = this.novo || !this.id
				? await criarItemDeEstoque()
				: await this.findEstoqueUseCase.getEstoque(this.idDeposito, this.id)

			this.qtdeEstoque = this.estoque.qtdeEstoque || 0
			this.qtdeEmpenhada = this.estoque.qtdeEmpenhada || 0
			this.estoqueMinimo = this.estoque.estoqueMinimo || 0
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async salvar() {
		if (!this.estoque) return

		if(!this.estoque.produto) return

		try {
			this.salvando = true
			if (
				(this.estoque.qtdeEmpenhada as number) >
				(this.estoque.qtdeEstoque as number)
			)
				throw new Error(
					'Quantidade em estoque deve ser maior ou igual a quantidade empenhada',
				)
			if (this.estoque.id) {
				if ((this.estoque.qtdeEstoque as number) > this.qtdeEstoque) {
					const qtdAdd = fixarCasasDecimais((this.estoque.qtdeEstoque as number) - this.qtdeEstoque, 9)
					await this.saveEstoqueUseCase.add(this.estoque.id, Number(qtdAdd), String(this.estoque.observacao))
				}

				if ((this.estoque.qtdeEmpenhada as number) > this.qtdeEmpenhada) {
					const qtdeAEmpenhar =
						fixarCasasDecimais((this.estoque.qtdeEmpenhada as number) - this.qtdeEmpenhada, 9)
					await this.saveEstoqueUseCase.empenhar(this.estoque.id, Number(qtdeAEmpenhar), String(this.estoque.observacao))
				}

				if ((this.estoque.qtdeEstoque as number) < this.qtdeEstoque) {
					const qtdeARetirar =
						fixarCasasDecimais(this.qtdeEstoque - (this.estoque.qtdeEstoque as number), 9)
					await this.saveEstoqueUseCase.retirar(this.estoque.id, qtdeARetirar, String(this.estoque.observacao))
				}

				if ((this.estoque.qtdeEmpenhada as number) < this.qtdeEmpenhada ) {
					const qtdeAReaver =
						fixarCasasDecimais(this.qtdeEmpenhada - (this.estoque.qtdeEmpenhada as number), 9)
					await this.saveEstoqueUseCase.reaver(this.estoque.id, qtdeAReaver, String(this.estoque.observacao))
				}

				if ((this.estoque.estoqueMinimo as number) < this.estoqueMinimo || (this.estoque.estoqueMinimo as number) > this.estoqueMinimo) {

					const estoqueProjection: EstoqueProjection = {
						id:"",
						nomeCompletoDoProduto:"",
						nomeDaLoja:"",
						skuDoProduto:"",
						eansDoProduto:[],
						qtdeEstoque:this.qtdeEstoque,
						qtdeEmpenhada:this.qtdeEmpenhada,
						estoqueMinimo:this.estoque.estoqueMinimo,
						skuProdutoPai:"",
					}
					await this.saveEstoqueUseCase.alterarEstoqueMinimo(this.estoque.id, estoqueProjection)
				}

			} else {
				await this.saveEstoqueUseCase.create(
					this.idDeposito,
					this.estoque,
				)
			}

			AlertModule.setSuccess('Item salvo com sucesso')
			this.$router.go(-1)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	get deposito() {
		return DepositoModule.depositoSelecionado
	}

	get tiposDeProdutos() {
		return { tipos: ['Padrão', 'Variante'] as TipoDeProduto [] }
	}

	get idLoja() { 
		return this.$route.query.l as string 
	}
}

function criarItemDeEstoque(): FormDeItemDeEstoque {
	return {
		produto: null,
		qtdeEstoque: 0,
		qtdeEmpenhada: 0,
		estoqueMinimo: 0,
		observacao: '',
	}
}
